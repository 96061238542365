import { render, staticRenderFns } from "./AgreeMent.vue?vue&type=template&id=6592364a&scoped=true&"
import script from "./AgreeMent.vue?vue&type=script&lang=js&"
export * from "./AgreeMent.vue?vue&type=script&lang=js&"
import style0 from "./AgreeMent.vue?vue&type=style&index=0&id=6592364a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6592364a",
  null
  
)

export default component.exports