<template>
  <agree-ment class="mobile"></agree-ment>
</template>

<script>
import AgreeMent from '@/views/PC/agreeMent/AgreeMent.vue';
export default {
  components: {
    AgreeMent,
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  letter-spacing: vv(0.5);
  padding: vv(15) !important;
  ::v-deep .langugage {
    font-size: vv(14) !important;
    padding-right: vv(30) !important;
  }
  ::v-deep .icon-back_icon {
    font-size: vv(14) !important;
  }
  ::v-deep h3.title {
    font-weight: 500;
    font-size: vv(20);
    padding: vv(10) 0 !important;
  }
  ::v-deep h4 {
    font-weight: 500;
    font-size: vv(18);
    padding: vv(5) 0 !important;
  }
  ::v-deep p {
    font-size: vv(14);
    padding: vv(5) 0 !important;
  }

  ::v-deep span {
    padding: 0 vv(5) !important;
  }
}
</style>
